body {
  margin: 0;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
.Polaris-Choice {
  align-items: center !important;
}
@media screen and (max-width: 490px) {
  .Polaris-IndexFilters__IndexFiltersSticky {
    display: none;
  }
  .Polaris-IndexTable__TableHeading--first .Polaris-Checkbox__Backdrop {
    display: none;
  }
}
.connect-to-paypal {
  padding: 0.625em 2em;
  max-width: 375px;
  font-size: 16px;
  background-image: -webkit-linear-gradient(#0070ba 20%, #0070ba) !important;
  background-image: linear-gradient(#0070ba 20%, #0070ba) !important;
  background-repeat: no-repeat !important;
  border-color: #0070ba;
  position: relative;
  white-space: nowrap;
  line-height: 1.6;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  width: max-content;
  display: block;
}
.connect-to-paypal svg {
  margin: 0px 9px 0px 0px;
  fill: #a8cde5;
  vertical-align: middle;
}
.connect-to-paypal .PPTM-btm {
  fill: #a8cde5;
}
.connect-to-paypal .PPTM-top {
  fill: #ffffff;
}
.connect-to-paypal b {
  fill: #fff;
}
.connect-to-paypal:hover,
.connect-to-paypal:active {
  background-image: -webkit-linear-gradient(#003087 20%, #003087) !important;
  background-image: linear-gradient(#003087 20%, #003087) !important;
  background-repeat: no-repeat !important;
}
.connect-to-paypal:hover b {
  text-decoration: underline;
}
.Polaris-InlineError__Icon {
  display: flex;
  align-items: center;
}
.horizontal-4 {
  margin: 0px 4px;
}
.Polaris-PositionedOverlay {
  font-size: 14px;
}
.Polaris-List__Item {
  font-size: 14px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-32 {
  margin-top: 32px;
}
.mb-16 {
  margin-bottom: 16px;
}
.margin-top-4 {
  margin-top: 4px;
}
.margin-top-8 {
  margin-top: 8px;
}
.right-content {
  justify-content: flex-end;
}
.relative {
  position: relative;
}
.province-remove-btn {
  position: absolute;
  right: 20px;
  cursor: pointer;
}
.province-remove-btn-refund {
  position: absolute;
  right: 0px;
  top: -24px;
  cursor: pointer;
}
.Polaris-DatePicker__Header {
  margin-top: 1rem;
  position: unset;
}
.date-picker-btn-group {
  display: flex;
  justify-content: flex-end;
}
.mt-6 {
  margin-top: 6px;
}
.ml-8 {
  margin-left: 8px;
}
.pd-24 {
  padding: 24px;
}
.mt-24 {
  margin-top: 24px;
}
.mb-8 {
  margin-bottom: 8px;
}
.justify-content-center {
  justify-content: center;
}
.d-flex {
  display: flex;
}
.carousel-email-consent {
  max-width: 100%;
}
.email-consent-content {
  padding: 80px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-slide {
  min-height: 100%;
}
.carousel-email-consent img {
  max-width: 100%;
  max-height: 668px;
  min-width: 100%;
  min-height: 668px;
  display: flex;
}
.content-center {
  display: flex;
  align-items: center;
}
.text-center {
  text-align: center !important;
}
.alice-carousel__next-btn,
.alice-carousel__prev-btn {
  display: none;
}
.alice-carousel__dots {
  margin-top: 0;
}
.Polaris-OptionList-Option__Icon {
  margin: 0;
}
.date-picker-options .Polaris-OptionList-Option {
  width: 120px;
}
.Polaris-IndexTable__TableCell--first .Polaris-IndexTable-Checkbox__TableCellContentContainer {
  display: none !important;
}
.Polaris-IndexTable__TableHeading--first {
  padding: 0 !important;
  max-width: 0 !important;
}
.Polaris-IndexTable__TableCell--first {
  padding: 0 !important;
  max-width: 0 !important;
}
.Polaris-IndexTable__StickyTableHeader--isSticky {
  visibility: hidden;
}
.modal-footer {
  min-width: 588px;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 767px) {
  .modal-footer {
    min-width: calc(100vw - 32px);
  }
}
.provide-evidence .Polaris-Icon {
  margin: 0;
  margin-right: 0.5rem;
}
.w-100 {
  width: 100%;
}
.pd-24 {
  padding: 1.5rem;
}
.p-3 {
  padding: 1rem;
}
.mt-3 {
  margin-top: 1rem;
}
select {
  padding-left: 1rem;
}
.mt-4 {
  margin-top: 1.5rem;
}
.mt-8 {
  margin-top: 0.5rem;
}

.mt-16 {
  margin-top: 1rem;
}
.mb-16 {
  margin-bottom: 1rem;
}
.Polaris-IndexTable__TableRow {
  cursor: auto;
}
.social-img {
  width: 40px;
  height: 40px;
}
.elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
